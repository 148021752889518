var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "image-container col-md-4" }, [
      _c("img", {
        attrs: { src: _vm.bizIdImage, alt: _vm.content.image.displayName },
      }),
    ]),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-container col-md-8" }, [
      _c("h4", { staticClass: "card-body-title" }, [
        _vm._v("\n      90-Day Free Trial Offer\n    "),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "\n      We provide everything to instantly stand up your business online,\n      using the latest and most secure software and services.\n    "
        ),
      ]),
      _c("div", { staticClass: "card-body-text" }, [
        _c("div", { staticStyle: { display: "flex" } }, [
          _c("div", { staticStyle: { flex: "1" } }, [
            _c("ul", { staticClass: "product-list" }, [
              _c("li", [
                _c("strong", [
                  _vm._v("\n                Domain name\n              "),
                ]),
                _c("br"),
                _c("span", [
                  _vm._v(
                    "\n                First year free, low renewal fees afterwards\n              "
                  ),
                ]),
              ]),
              _c("li", [
                _c("strong", [
                  _vm._v("\n                Pre-built website\n              "),
                ]),
                _c("br"),
                _c("span", [
                  _vm._v(
                    "\n                Hosted by us, finely-tuned and owned by you\n              "
                  ),
                ]),
              ]),
              _c("li", [
                _c("strong", [
                  _vm._v("\n                SSL Certificate\n              "),
                ]),
                _c("br"),
                _c("span", [
                  _vm._v(
                    "\n                Full website security\n              "
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticStyle: { flex: "1" } }, [
            _c("ul", [
              _c("li", [
                _c("strong", [
                  _vm._v("\n                Email\n              "),
                ]),
                _c("br"),
                _c("span", [
                  _vm._v(
                    "\n                Up to 10 addresses on a shared domain\n              "
                  ),
                ]),
              ]),
              _c("li", [
                _c("strong", [
                  _vm._v(
                    "\n                Business Phone Service\n              "
                  ),
                ]),
                _c("br"),
                _c("span", [
                  _vm._v(
                    "\n                Local phone number & dedicated app\n              "
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }