<template>
  <div class="row">
    <div class="image-container col-md-4">
      <img :src="bizIdImage" :alt="content.image.displayName">
    </div>
    <div class="text-container col-md-8">
      <h4 class="card-body-title">
        90-Day Free Trial Offer
      </h4>
      <br>
      <p>
        We provide everything to instantly stand up your business online,
        using the latest and most secure software and services.
      </p>

      <div class="card-body-text">
        <div style="display: flex">
          <div style="flex: 1">
            <ul class="product-list">
              <li>
                <strong>
                  Domain name
                </strong>
                <br>
                <span>
                  First year free, low renewal fees afterwards
                </span>
              </li>
              <li>
                <strong>
                  Pre-built website
                </strong>
                <br>
                <span>
                  Hosted by us, finely-tuned and owned by you
                </span>
              </li>
              <li>
                <strong>
                  SSL Certificate
                </strong>
                <br>
                <span>
                  Full website security
                </span>
              </li>
            </ul>
          </div>

          <div style="flex: 1">
            <ul>
              <li>
                <strong>
                  Email
                </strong>
                <br>
                <span>
                  Up to 10 addresses on a shared domain
                </span>
              </li>
              <li>
                <strong>
                  Business Phone Service
                </strong>
                <br>
                <span>
                  Local phone number &amp; dedicated app
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProductPitchVersionB',
  props: {
    content: {
      type: Object,
      default: null,
    },
    bizIdImage: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
#online-presence-section {
  .card-body {
    margin-bottom: 1em;

    .card-body-title {
      text-align: left;
      margin: 0;
      padding: 0;
      font-weight: 800;
    }
    .image-container {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 12.5em;
        margin-top: 1em;
      }
    }
    .text-container {
      display: flex;
      flex-direction: column;
      text-align: left;
      justify-content: space-between;

      .card-body-text {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: flex-start;

        .top-description, .bottom-description{
          max-width: 28em;
          text-align: left;

          span {
            font-weight: bold;
          }
        }

        .product-list {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 0;
          padding-left: 2em;
        }
      }
    }
  }
}
</style>
